@import "./colors";
/* Hero section [start] */
#hero {
  background-image: url(../assets/img/hero-bg-workstation.png);
  background-size: cover;
  background-position: top center;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.9;
    z-index: -1;
  }
  .hero {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
    justify-content: flex-start;
  }
  h1 {
    display: block;
    width: fit-content;
    font-size: 4rem;
    position: relative;
    color: transparent;
    animation: text_reveal 0.5s ease forwards;
    animation-delay: 1s;

    &:nth-child(1) {
      animation-delay: 1s;
    }
    &:nth-child(2) {
      animation-delay: 2s;
    }
    &:nth-child(3) {
      font-weight: 500;
      animation: text_reveal_name 0.5s ease forwards;
      animation-delay: 3s;
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: $primaryColor;
      animation: text_reveal_box 1s ease;
      animation-delay: 0.3s;
    }
    &:nth-child(1) span {
      animation-delay: 0.5s;
    }
    &:nth-child(2) span {
      animation-delay: 1.5s;
    }
    &:nth-child(3) span {
      animation-delay: 2.5s;
    }
  }
}
/* Hero section [end] */

/* keyframes animations [start] */
@keyframes text_reveal_box {
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes text_reveal {
  100% {
    color: white;
  }
}
@keyframes text_reveal_name {
  100% {
    color: $primaryColor;
    font-weight: 500;
  }
}
/* keyframes animations [end] */

/* Media queries [start] */
@media only screen and (min-width: 768px) {
  #hero h1 {
    font-size: 7rem;
    line-height: 100px;
  }
}
/* Media queries [end] */
