@import "./colors";

#footer {
  background: $footerBgColor;
  .footer {
    min-height: 200px;
    width: 80%;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 10px;
  }
  h2 {
    color: white;
    font-weight: 500;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .social-icon {
    display: flex;
    margin-bottom: 30px;
  }
  .social-item {
    height: 50px;
    width: 50px;
    margin: 0 5px;
    img {
      filter: grayscale(1);
      transition: 0.3s ease filter;
    }
    &:hover img {
      filter: grayscale(0);
    }
  }
  p {
    color: white;
    font-size: 1.3rem;
  }
}
