@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap");
@import "./colors";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}
a,
a:link,
a:hover,
a:active,
a:visited {
  text-decoration: none !important;
}
.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  .loading-text {
    color: black;
  }
}
.error-page {
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: white;
  color: $errorTextColor;
  z-index: 10;
  h1 {
    font-size: 1.3rem;
    color: $errorTextColor !important;
  }
}
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
p {
  color: black;
  font-size: 1.6rem;
  margin-top: 5px;
  line-height: 2.5rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
}
.section-title {
  font-size: 4rem;
  font-weight: 300;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
}
.section-title span {
  color: $primaryColor;
}
.cta {
  display: inline-block;
  padding: 10px 30px;
  color: white;
  background-color: transparent;
  border: 2px solid $primaryColor;
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: 36px;
  transition: 0.3s ease;
  transition-property: background-color, color;
}
.cta:hover {
  color: white;
  background-color: $primaryColor;
}
.brand h1 {
  font-size: 3rem;
  text-transform: uppercase;
  color: white;
}
.brand span {
  color: $primaryColor;
}
.pointer {
  cursor: pointer;
}

/* Media queries [start] */
/* Tablet */
@media only screen and (min-width: 768px) {
  .cta {
    font-size: 2.5rem;
    padding: 20px 60px;
  }
  h1.section-title {
    font-size: 6rem;
  }
}
/* Media queries [end] */
