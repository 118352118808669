@import "./colors";

/* Contact form section [start] */
.contact-form-container {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  padding: 10px;
  .contact-form {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0 $shadowColor;
    transition: 0.3s ease box-shadow;
    &:hover {
      box-shadow: 0px 0px 5px 0 $shadowColor;
    }
    .title {
      text-align: center;
      font-size: 2.8rem;
      font-weight: 500;
      color: black;
      padding-top: 30px !important;
    }
    .note {
      line-height: 20px;
      font-size: 1.2rem;
      font-weight: bold;
    }
    label {
      font-size: 1.4rem;
      margin-top: 5px;
    }
    input,
    select,
    textarea,
    button {
      padding: 1rem;
      border-radius: 0.5rem;
      border: 0.1rem $formBorderColor solid;
      font-size: 1.6rem;
      font-family: Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    textarea {
      resize: none;
      width: 100%;
    }
    button {
      cursor: pointer;
    }
    button.primary {
      background-color: $primaryBtnBgColor;
      color: white;
      margin-top: 10px;
    }
    button.secondary {
      background-color: $secondaryBtnBgColor;
      margin-top: 10px;
    }
    button.block {
      width: 100%;
    }
    input:hover,
    textarea:hover,
    button:hover {
      border: 1px solid black;
    }
    .form-field {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
    .btn-container {
      margin-top: 20px;
    }
  }
}
/* Contact form section [end] */
/* Media queries [start] */
@media only screen and (min-width: 1127px) {
  .contact-form-container {
    min-width: 390px;
  }
}
@media only screen and (min-width: 768px) {
  .contact-form-container {
    min-width: 390px;
  }
}
/* Media queries [end] */