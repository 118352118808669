@import "./colors";
/* Navbar [start] */
header {
  position: fixed;
  left: 0;
  top: 0;
  height: 10vh;
  width: 100vw;
  padding: 25px;
  z-index: 100;
  transition: 0.5s ease background-color;
  .brand {
    position: absolute;
    top: 25%;
    left: 5%;
  }
}
nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.nav-links {
  display: flex;
  list-style: none;
  height: 100%;
  width: 50%;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-top: auto;

  li {
    a {
      color: white;
      text-decoration: none;
      font-size: 16px;
      transition: color 0.3s ease;
    }
    &:hover a {
      color: $primaryColor;
    }
  }
}

@media screen and (max-width: 960px) {
  header .brand {
    top: 35%;

    h1 {
      font-size: 2.5rem;
    }
  }
  nav {
    width: 70%;
    justify-content: start;
  }
  .line {
    width: 30px;
    height: 3px;
    background: white;
    margin: 5px;
  }
  .hamburger {
    position: absolute;
    width: 30%;
    left: 90%;
    top: 55%;
    transform: translate(-5%, -50%);
    z-index: 2;
    cursor: pointer;

    div {
      transition: all 0.3s ease;
    }
  }
  .nav-links {
    position: fixed;
    background-color: $circleBgColor;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    flex-direction: column;
    /* [dimensione] at [posDX] [posTOP]*/
    clip-path: circle(100px at 90% -15%);
    -webkit-clip-path: circle(100px at 90% -15%);
    transition: all 1s ease-in-out;
    pointer-events: none;

    li {
      opacity: 0;
      a {
        font-size: 25px;

        &::after {
          content: attr(data-after);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          color: rgb(240, 248, 255);
          opacity: 0.02;
          font-size: 12rem;
          letter-spacing: 50px;
          z-index: -1;
          transition: 0.3s ease letter-spacing;
        }
      }
      &:hover a::after {
        transform: translate(-50%, -50%) scale(1);
        letter-spacing: initial;
      }
      &:nth-child(1) {
        transition: all 0.3s ease 0.2s;
      }
      &:nth-child(2) {
        transition: all 0.3s ease 0.5s;
      }
      &:nth-child(3) {
        transition: all 0.3s ease 0.8s;
      }
      &:nth-child(4) {
        transition: all 0.3s ease 1.1s;
      }
      &:nth-child(5) {
        transition: all 0.3s ease 1.4s;
      }
    }
    li.fade {
      opacity: 1 !important;
    }
  }
  .nav-links.open {
    /* [dimensione] at [posDX] [posTOP]*/
    clip-path: circle(1500px at 100% -15%);
    -webkit-clip-path: circle(1500px at 100% -15%);
    pointer-events: all;
  }
  .toggle .line1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  .toggle .line2 {
    opacity: 0;
  }
  .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
/* Navbar [end] */
