/* General colors */
$primaryColor: #ff0055;
$shadowColor: #0000002c;
$errorTextColor: #b91313;
$successTextColor: #05b823;

/* Nav bar*/
$navbarBgColor: #29323c;
$circleBgColor: #131418;

/* Timeline */
$timelineVerticalBarColor: #3a3b3c;
$dateColor: #b0b3b8;
$dateBgColor: #4e4f50;
$eventBoxBgColor: #242526;
$eventDotColor: #ff0055;
$eventTitleColor: #e4e6eb;
$eventTitleSeparatorColor: #3a3b3c;

/**/
$footerBgColor: #242526;

/* Form */
$formBgColor: #ececec;
$formBorderColor: #787878;
$primaryBtnBgColor: #3d3d3d;
$secondaryBtnBgColor: #cacaca;

/* Table */
$headerBgColor: #787878;
$evenRowBgColor: #ececec;
