@import "./colors";

.admin {
  #top {
    background-image: url(../assets/img/hero-bg-workstation.png);
    background-size: cover;
    background-position: top center;
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.9;
      z-index: -1;
    }
  }
  .column {
    flex-direction: column;
  }
  h1 {
    color: white;
  }
  h2 {
    font-size: 3rem;
    margin-top: 150px;
    margin-bottom: 20px;
  }
  .cta {
    color: white;
    margin: 20px 10px;
    padding: 10px;
    font-size: 2rem;
  }
  .cta:hover {
    color: white;
  }
}
.admin-nav-links {
  position: absolute;
  top: 30%;
  left: 86%;
  button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 10%;
    cursor: pointer;
  }
  img {
    width: 35px;
    height: 35px;
  }
}

.table-form-container {
  width: 98%;
  display: flex;
  flex-direction: row;
}
.table-container {
  width: 70%;
  padding: 10px;
  overflow-x: scroll;
  table {
    width: 100%;
    font-size: 1.2rem;
    border: none;
    .table-head {
      text-align: left;
      background-color: $headerBgColor;
    }
    th {
      font-size: 1.5rem;
      padding: 10px;
    }
    td {
      font-size: 1.2rem;
      padding: 10px;
      .action {
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;
        text-decoration: underline;
        margin-right: 5px;
      }
    }
    tr:nth-child(even) {
      background-color: $evenRowBgColor;
    }
  }
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 10px;
  .form {
    background-color: $formBgColor;
    border: 1px solid $formBorderColor;
    padding: 20px;
    border-radius: 10px;
    .title {
      text-align: center;
      font-size: 2rem;
      color: black;
    }
    .note {
      line-height: 20px;
      font-size: 1.2rem;
      font-weight: bold;
    }
    label {
      font-size: 1.4rem;
      margin-top: 5px;
    }
    input,
    select,
    textarea,
    button {
      padding: 1rem;
      border-radius: 0.5rem;
      border: 0.1rem $formBorderColor solid;
      font-size: 1.6rem;
      font-family: Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    textarea {
      resize: none;
      width: 100%;
    }
    button {
      cursor: pointer;
    }
    button.primary {
      background-color: $primaryBtnBgColor;
      color: white;
      margin-top: 10px;
    }
    button.secondary {
      background-color: $secondaryBtnBgColor;
      margin-top: 10px;
    }
    button.block {
      width: 100%;
    }
    input:hover,
    textarea:hover,
    button:hover {
      border: 1px solid black;
    }
    .form-field {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }
    .btn-container {
      margin-top: 20px;
    }
  }
}
.outer-link {
  text-align: center;
  font-size: 1.3rem;
  margin-top: 10px;
}
.error-text {
  font-size: 1rem;
  color: $errorTextColor;
  font-weight: bold;
}
.success-text {
  font-size: 1rem;
  color: $successTextColor;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  .admin {
    h2 {
      margin-top: 100px;
    }
    .table-form-container {
      width: 100%;
    }
    .form-container {
      width: 60%;
    }
    .table-container {
      width: 100%;
    }
    .cta {
      display: block;
      text-align: center;
    }
  }
  .form-container {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .admin {
    .form-container {
      width: 100%;
    }
    .cta {
      display: block;
    }
    .table-form-container {
      flex-direction: column;
    }
  }
  .form-container {
    width: 100%;
  }
}
