@import "./colors";

#skills {
  background-size: cover;
  background-position: top center;
  .skills {
    flex-direction: column;
    text-align: center;
    max-width: 1500px;
    margin: 0 auto;
    padding: 100px 0 50px 0;
  }
  .skills-header {
    h1 {
      margin: 60px 0;
    }
    p {
      margin: 0px 40px;
    }
  }
  .skills-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .skill-item {
    flex-basis: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;
    background-image: url(../assets/img/img-1.png);
    background-position-x: center;
    background-size: cover;
    margin: 10px 5%;
    position: relative;
    z-index: 1;
    color: white;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgb(24, 24, 51);
      opacity: 0.6;
      z-index: -1;
    }
    .icon {
      height: 80px;
      width: 80px;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 2rem;
      color: white;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    p {
      color: white;
      text-align: left;
    }
  }
}
@media only screen and (min-width: 768px) {
  #skills .skills-cards .skill-item {
    flex-basis: 45%;
    margin: 1.5%;
  }
}
@media only screen and (min-width: 1200px) {
  #skills .skills-cards .skill-item {
    flex-basis: 22%;
    margin: 1.5%;
  }
}
