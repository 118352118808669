@import "./colors";

#experiences {
  .experiences {
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 0 50px 0;
  }
  .experiences-header {
    h1 {
      margin: 60px 0;
    }
  }
}
/* TIMELINE */
.experiences-timeline {
  position: relative;
  margin: 50px auto 0;
  padding: 0 40px;
  width: 1160px;
  ul {
    list-style: none;
  }
  &::before {
    position: absolute;
    content: "";
    left: 50%;
    top: -40px;
    width: 2px;
    height: 100%;
    background: $timelineVerticalBarColor;
  }
  .time {
    font-size: 1.6rem;
    color: $dateColor;
  }
  li {
    position: relative;
    width: 50%;
    padding: 20px 40px;
    background: $eventBoxBgColor;
    border-radius: 8px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    &:nth-child(odd) {
      text-align: right;
      margin-right: auto;
      margin-left: -20px;
      &::before {
        content: "";
        position: absolute;
        top: 24px;
        right: -26px;
        width: 10px;
        height: 10px;
        background: $eventDotColor;
        border-radius: 50%;
      }
      .time {
        position: absolute;
        top: 12px;
        right: -200px;
        padding: 8px 16px;
        background: $dateBgColor;
        border-radius: 18px;
      }
    }
    &:nth-child(even) {
      text-align: left;
      margin-left: auto;
      margin-right: -20px;
      &::before {
        content: "";
        position: absolute;
        top: 24px;
        left: -24px;
        width: 10px;
        height: 10px;
        background: $eventDotColor;
        border-radius: 50%;
      }
      .time {
        position: absolute;
        top: 12px;
        left: -200px;
        padding: 8px 16px;
        background: $dateBgColor;
        border-radius: 18px;
      }
    }
  }
}
.timeline-item {
  padding-bottom: 20px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $eventTitleColor;
    padding-bottom: 20px;
    border-bottom: 1px solid $eventTitleSeparatorColor;
    margin-bottom: 20px;
    font-size: 2rem;
  }
  .icon {
    height: 80px;
    width: 80px;
    margin-right: 10px;
  }
  p {
    color: white;
    position: relative;
  }
  &:hover {
    transform: scale(1.06);
    transition: 0.3s ease transform;
  }
}
@media only screen and (max-width: 1200px) {
  .experiences-timeline {
    width: 100%;
    padding: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .experiences-timeline {
    &::before {
      left: 20px;
    }
    li {
      &:nth-child(odd),
      &:nth-child(even) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        text-align: left;
        .time {
          top: -17px;
          left: 40px;
          right: inherit;
        }
        &::before {
          top: -5px;
        }
      }
      &:nth-child(odd) {
        &::before {
          left: -24px;
        }
      }
    }
    .timeline-item {
      .title {
        padding-top: 20px;
      }
    }
  }
}
