@import "./colors";

#about {
  .about {
    flex-direction: column-reverse;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 0 50px 0;
  }
  .col-left {
    width: 250px;
    height: 360px;
    margin-right: 50px;
    .about-img {
      position: relative;
      img {
        filter: drop-shadow(0px 5px 3px black);
      }
      &::after {
        content: "";
        position: absolute;
        left: -33px;
        top: 30px;
        height: 95%;
        width: 95%;
        border: 7px solid $primaryColor;
        z-index: -1;
      }
    }
  }
  .col-right {
    width: 100%;
    h2 {
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 0.2rem;
      margin-bottom: 10px;
    }
    p {
      margin: 20px 40px;
    }
    .cta {
      color: black;
      margin-bottom: 50px;
      padding: 10px 20px;
      font-size: 2rem;
    }
  }
}

/* Media queries [start] */
/* Tablet */
@media only screen and (min-width: 768px) {
  #about {
    .about {
      flex-direction: row;
    }
    .col-left {
      width: 600px;
      height: 400px;
      padding-left: 60px;
      .about-img::after {
        left: -45px;
        top: 34px;
        height: 98%;
        width: 98%;
        border: 10px solid $primaryColor;
      }
    }
    .col-right {
      text-align: left;
      padding: 30px;
      h1 {
        text-align: left;
      }
      p {
        margin: 20px 0;
      }
    }
  }
}

/* Desktop */
@media only screen and (min-width: 1200px) {
  #about .col-right p {
    margin: 20px 0;
  }
}
/* Media queries [end] */
