@import "./colors";
#contact {
  .contact {
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 100px 0 50px 0;
    h1 {
      padding-top: 50px;
    }
    .boxes {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 20px;
    }
  }
  .contact-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .contact-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-basis: 80%;
      max-width: 300px;
      padding: 30px;
      margin: 30px;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0px 0px 18px 0 $shadowColor;
      transition: 0.3s ease box-shadow;
      &:hover {
        box-shadow: 0px 0px 5px 0 $shadowColor;
      }
      .icon {
        width: 70px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      .contact-info {
        h1 {
          font-size: 2.8rem;
          font-weight: 500;
          margin-bottom: 5px;
        }
        p {
          line-height: 2rem;
          font-weight: 500;
        }
        a {
          font-size: 1.6rem;
        }
      }
    }
  }
}
/* Contact section [end] */

/* Media queries [start] */
@media only screen and (min-width: 768px) {
  #contact .contact-item {
    flex-basis: 45%;
  }
}
@media only screen and (min-width: 1127px) {
  #contact .contact .boxes {
    flex-direction: row;
  }
}
/* Media queries [end] */
